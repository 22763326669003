<template>
  <div class="router-error-404-catchall">
    <titleBar
      :title="'Soon...'"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />
    <e404 />
    <!--
      <h1>This is an 404 page - not found...</h1>
  
  
          <h1>This is an 404 page - not found...</h1>
              <h1>This is an 404 page - not found...</h1>
                  <h1>This is an 404 page - not found...</h1>
                      <h1>This is an 404 page - not found...</h1>
  
                    -->
  </div>
</template>
<script>
import e404 from "@/components/e/e404";

export default {
  data() {
    return {
      transitionName: "fade", //default
    };
  },
  components: {
    e404,
    //  Footer: Footer
  },
};
</script>
